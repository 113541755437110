import { LinkProps } from 'next/link';

import { useGlobal } from '@hultafors/hultaforsgroup/hooks';

import { ButtonVariant, StyledButton, StyledButtonLink } from './button.styled';

interface ButtonProps {
  to?: LinkProps['href'];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  variant?: ButtonVariant;
  loading?: boolean;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  loading,
  variant = 'black',
  to,
  onClick,
  children,
  className,
}) => {
  const { globalContent: global } = useGlobal();
  if (to && onClick) {
    console.error("Can't have both onClick and to prop in Button component");
  }

  const extraProps =
    typeof to === 'string' &&
    (to.startsWith('http:// ') || to.startsWith('https://'))
      ? { rel: 'noreferrer noopener', target: '_blank' }
      : {};

  return to ? (
    <StyledButtonLink
      $variant={variant}
      href={to}
      className={className}
      {...extraProps}
    >
      {children}
    </StyledButtonLink>
  ) : (
    <StyledButton
      $variant={variant}
      onClick={onClick}
      disabled={loading}
      className={className}
    >
      {loading ? global.loading : children}
    </StyledButton>
  );
};
