import { getDarkestColor } from '../get-darkest-color';

type ColorField = {
  hex: string;
};

type GetImageLoadStyleInput = {
  blurUpThumb?: string | null;
  colors: Array<Partial<ColorField>>;
};

export function getImageLoadStyle<
  T extends GetImageLoadStyleInput,
  R = React.CSSProperties,
>(image: undefined | null | T, extra: Record<string, string> = {}): R {
  const style: Record<string, string> = { ...extra };
  if (image?.blurUpThumb) {
    style['--background-image'] = `url(${image.blurUpThumb})`;
  }
  const darkestColor = getDarkestColor(image?.colors?.map(({ hex }) => hex));
  if (darkestColor) {
    style['--background-color'] = darkestColor;
  }
  return style as R;
}
