import { getLuminance } from './get-luminance';

export function getDarkestColor(
  colors?: (string | undefined)[] | null,
): string {
  if (!colors?.length) {
    return '';
  }
  return (
    colors.reduce((prev, current) => {
      if (!current || !prev) {
        return prev || '';
      }
      return getLuminance(prev) < getLuminance(current) ? prev : current;
    }) || ''
  );
}
