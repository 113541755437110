import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

import { breakpoints } from '@hultafors/shared/constants';

import { routes } from '@hultafors/hultaforsgroup/helpers';
import {
  BrandFragment,
  BrandsBlockFragment,
} from '@hultafors/hultaforsgroup/types';

import styles from './brands-block.module.scss';

type BrandsBlockProps = Omit<BrandsBlockFragment, 'id'>;

export const BrandsBlock: React.FC<BrandsBlockProps> = ({
  title,
  brands = [],
  introText,
}) => {
  if (!title && !brands.length && !introText) {
    return null;
  }
  const brandMapper = ({ brandName, slug, image }: BrandFragment) => (
    <Link
      key={`key-${brandName}`}
      className={styles['link']}
      href={{ pathname: `${routes.brands}/${slug}` }}
    >
      <div className={styles['image-wrapper']}>
        {image?.responsiveImage?.src && (
          <Image
            src={image.responsiveImage.src || ''}
            blurDataURL={image.responsiveImage?.base64 || ''}
            placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
            alt={image.alt || brandName || ''}
            sizes={`50vw, (min-width: ${breakpoints.tablet}) 25vw, (min-width: ${breakpoints.desktop}) 148px`}
            fill
          />
        )}
      </div>
      <p className={clsx('p', 'small')}>{brandName}</p>
    </Link>
  );

  return (
    <section
      className={clsx('content-container', 'wide', styles['wrapper'])}
      id="our-brands"
      data-test="OurBrandsBlock"
    >
      <h2 className={clsx('h1', styles['title'])}>{title}</h2>
      {introText && <p className={clsx('h3', styles['intro'])}>{introText}</p>}
      <div className={styles['grid']}>{brands.map(brandMapper)}</div>
    </section>
  );
};
