import { css } from 'styled-components';

import {
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/hultaforsgroup/helpers';

export const header1BoldStyle = css`
  font-size: ${fontSizes.header1};
  line-height: ${lineHeights.header1};
  font-weight: ${fontWeights.fontBold};
`;
export const header1MediumStyle = css`
  font-size: ${fontSizes.header1};
  line-height: ${lineHeights.header1};
  font-weight: ${fontWeights.fontMedium};
  letter-spacing: 0.01em;
`;
export const header1Style = css`
  font-size: ${fontSizes.header1};
  line-height: ${lineHeights.header1};
  font-weight: ${fontWeights.fontRegular};
  letter-spacing: 0.01em;
`;
export const header1MobileStyle = css`
  font-size: ${fontSizes.header1Mobile};
  line-height: ${lineHeights.header1Mobile};
  font-weight: ${fontWeights.fontRegular};
  letter-spacing: 0.01em;
`;
export const header1MobileMediumStyle = css`
  font-size: ${fontSizes.header1Mobile};
  line-height: ${lineHeights.header1Mobile};
  font-weight: ${fontWeights.fontMedium};
  letter-spacing: 0.01em;
`;
export const header2MediumStyle = css`
  font-size: ${fontSizes.header2Medium};
  line-height: ${lineHeights.header2Medium};
  font-weight: ${fontWeights.fontMedium};
`;
export const header2BoldStyle = css`
  font-size: ${fontSizes.header2};
  line-height: ${lineHeights.header2};
  font-weight: ${fontWeights.fontBold};
`;
export const header2Style = css`
  font-size: ${fontSizes.header2};
  line-height: ${lineHeights.header2};
  font-weight: ${fontWeights.fontRegular};
`;
export const header3Style = css`
  font-size: ${fontSizes.header3};
  line-height: ${lineHeights.header3};
  font-weight: ${fontWeights.fontRegular};
`;
export const header4Style = css`
  font-size: ${fontSizes.header4};
  line-height: ${lineHeights.header4};
  font-weight: ${fontWeights.fontRegular};
`;
export const header4MediumStyle = css`
  font-size: ${fontSizes.header4};
  line-height: ${lineHeights.header4};
  font-weight: ${fontWeights.fontMedium};
`;
