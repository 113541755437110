import styled from 'styled-components';

import { colors } from '@hultafors/hultaforsgroup/helpers';

export const RouterLinkStyled = styled.span`
  a {
    block-size: 100%;
    color: ${colors.black};
    text-decoration: none;
    border-block-end: 1px solid transparent;

    &:hover {
      transition: all 0.2s;
      border-block-end: 1px solid ${colors.HGBlack};
    }

    &:focus {
      color: inherit;
      box-shadow: none !important;
      outline: 0;
      border: 3px auto -webkit-focus-ring-color !important;
    }

    &:active {
      color: inherit;
    }
  }
`;
