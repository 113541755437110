import { ImageLoader } from 'next/image';

export const datoImageLoader: ImageLoader = ({ src, width, quality }) => {
  const url = new URL(src);
  let w = parseInt(url.searchParams.get('w') ?? '4', 10);
  let h = parseInt(url.searchParams.get('h') ?? '3', 10);
  const ar = w / h;
  w = width;
  h = width / ar;
  url.searchParams.set('w', String(w));
  url.searchParams.set('h', String(h));

  if (quality) {
    url.searchParams.set('q', String(quality));
  }
  return url.href;
};
