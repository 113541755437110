import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultaforsgroup/helpers';

export const NavigationDesktopWrapper = styled.div`
  height: ${spacing.menuHeight};
  display: none;
  margin: auto;
  max-width: ${breakpoints.desktopLarger};
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    display: flex;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.white};
`;

export const TopMenu = styled.div`
  display: flex;
  align-self: center;
`;

export const NavButton = styled.button<{ $active?: boolean }>`
  appearance: none;
  border: none;
  background-color: transparent;
  outline: none;
  padding: ${spacing.xsmall} 0;
  color: ${colors.HGWhite};
  font-size: ${fontSizes.body12};
  font-weight: ${fontWeights.fontRegular};
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &:not(:last-child) {
    margin-inline-end: ${spacing.regular};
  }

  &:hover {
    border-block-end: 1px solid ${colors.HGWhite};
    cursor: pointer;
  }
  ${({ $active }) =>
    $active
    && css`
      border-bottom: 1px solid ${colors.HGWhite};
    `}

  @media screen and (width >= 1220px) {
    font-size: ${fontSizes.body14};
  }
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.HGWhite};
  font-size: ${fontSizes.body12};
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &:not(:last-child) {
    margin-inline-end: ${spacing.regular};
  }

  &:hover {
    border-block-end: 1px solid ${colors.HGWhite};
  }

  @media screen and (width >= 1220px) {
    font-size: ${fontSizes.body14};
  }
`;

export const TopActions = styled.div`
  display: flex;
  align-items: center;
`;

const SharedButton = css`
  margin-inline: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

export const TogglerButton = styled.button`
  ${SharedButton}
`;

export const PartnerLink = styled(Link)`
  ${SharedButton}
`;

export const IconLabel = styled.span`
  font-size: 12px;
  color: ${colors.HGWhite};
`;
