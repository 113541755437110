import Link from 'next/link';
import styled from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hultaforsgroup/helpers';

import { Paragraph } from '../paragraph/paragraph';

export const Address = styled(Paragraph).attrs({ styleType: 'body16' })`
  margin: 0 0 ${spacing.small} 0;
`;

export const ViewOnMap = styled(Paragraph).attrs({ styleType: 'body16' })`
  margin-block: ${spacing.regular} ${spacing.small};
`;

export const StoreItemStyled = styled.div`
  a {
    text-decoration: none;
    font-size: ${fontSizes.body16};
    color: ${colors.HGBlack};
  }

  .Contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;

    &.EmailAndPhoneWrapper {
      flex-direction: column;
      block-size: auto;
      padding: 0 0 ${spacing.small} 0;
      align-items: flex-start;
    }

    div {
      display: inline-flex;

      &.Email {
        padding: 0 0 ${spacing.small} 0;
      }
    }

    &.VisitSite {
      justify-content: flex-start;
      padding: ${spacing.small} 0;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          inline-size: 16px;
          block-size: 16px;
          margin-inline-start: 8px;
        }
      }
    }

    label {
      inline-size: 100%;
      margin-block-end: 6px;
    }

    &.StoreSells {
      align-items: center;
      padding: ${spacing.small} 0;

      .StoresWrapper {
        flex-direction: column;

        .storeText {
          color: ${colors.HGBlack};
          font-size: ${fontSizes.body16};
          line-height: ${lineHeights.body16};
        }
      }

      a {
        color: ${colors.HGBlack};
        font-size: ${fontSizes.body16};
        line-height: ${lineHeights.body16};
        text-decoration: underline;
      }
    }

    .Stores {
      inline-size: 100%;
      display: flex;
      flex-wrap: wrap;

      div {
        .Separator {
          display: inline-flex;
          margin: 0 5px;
        }
      }
    }
  }

  .MapLink {
    text-decoration: none;
  }
`;
export const LeftIconLink = styled(Link)`
  position: relative;
  padding-left: 20px;

  svg {
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: calc(50% - 8px);
    inline-size: 16px;
    block-size: 16px;
  }
`;
