import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { TwoColumnImageAndTextFragment } from '@hultafors/hultaforsgroup/types';

import { Paragraph } from '../paragraph/paragraph';

import {
  ImageWrapper,
  TextWrapper,
  Title,
  TwoColumnTextImageWrapper,
} from './two-column-text-image.styled';

type TwoColumnTextImageProps = Omit<TwoColumnImageAndTextFragment, 'id'>;

export const TwoColumnTextImage: React.FC<TwoColumnTextImageProps> = ({
  title,
  text,
  image,
  reverse,
}) => (
  <TwoColumnTextImageWrapper $reverse={reverse}>
    <TextWrapper>
      <Title>{title}</Title>
      <Paragraph styleType="body16">{text}</Paragraph>
    </TextWrapper>
    {image?.responsiveImage?.src && (
      <ImageWrapper $aspectRatio={image.responsiveImage.aspectRatio}>
        <Image
          src={image.responsiveImage.src}
          blurDataURL={image.responsiveImage?.base64 || ''}
          placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
          alt={image?.alt || ''}
          fill
          sizes={`100vw, (min-width: ${breakpoints.mobileMax}) 50vw, (min-width: ${breakpoints.maxGridWidth}) 488px`}
        />
      </ImageWrapper>
    )}
  </TwoColumnTextImageWrapper>
);
