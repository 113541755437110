import clsx from 'clsx';

import { getImageLoadStyle } from '@hultafors/shared/helpers/get-image-load-style/get-image-load-style';
import { getLuminance } from '@hultafors/shared/helpers/get-luminance';

import {
  HeroBlockFragment,
  ImageLoadFragment,
} from '@hultafors/hultaforsgroup/types';

import { Button } from '../button/button';
import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';
import { ResponsiveImage } from '../responsive-image/responsive-image';

import styles from './hero-block.module.scss';

interface HeroBlockProps extends Omit<HeroBlockFragment, 'id'> {
  index: number;
  priority?: boolean;
}

export const HeroBlock: React.FC<HeroBlockProps> = ({
  index,
  title,
  description,
  ctaText,
  ctaUrl,
  boxColor,
  priority,
  image,
}) => {
  const style: Record<string, string> = {
    ...getImageLoadStyle<ImageLoadFragment, Record<string, string>>(image),
  };

  const boxColorHex = boxColor?.hex || '';
  const isBoxColorDark = !boxColorHex ? false : getLuminance(boxColorHex) < 128;
  if (boxColorHex) {
    style['--box-color'] = boxColorHex;
  }
  const whiteText = !!image || !!isBoxColorDark;

  const sizes = ['100vw', '(min-width: 1600px) 1600px'].join(', ');

  return (
    <section
      className={clsx(styles['hero'], boxColorHex && styles['has-color'])}
      style={style}
      data-test="hero-block"
    >
      <div className={clsx(styles['content'])}>
        <ResponsiveImage
          className={styles['image-wrapper']}
          image={image}
          alt={title}
          sizes={sizes}
          priority
        />
        <div className={clsx('content-container', styles['inner'])}>
          {title && (
            <HTag
              className={clsx(
                boxColorHex ? 'h2' : 'h1',
                !!boxColorHex && ['mobile', 'medium'],
                styles['title'],
                whiteText && styles['white-text'],
              )}
              whiteText={whiteText}
              type={priority ? 'h1' : 'h2'}
            >
              {title}
            </HTag>
          )}

          {description && (
            <Paragraph
              className={clsx(
                styles['description'],
                whiteText && styles['white-text'],
              )}
              whiteText
            >
              {description}
            </Paragraph>
          )}
          {ctaUrl && ctaText && (
            <Button to={ctaUrl} variant={boxColor?.hex ? 'white' : undefined}>
              {ctaText}
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};
