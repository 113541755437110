import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/hultaforsgroup/helpers';

export const AlertStatuses = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
} as const;
export type AlertStatus = keyof typeof AlertStatuses;

export const AlertSizes = {
  large: 'large',
  medium: 'medium',
  small: 'small',
} as const;
export type AlertSize = keyof typeof AlertSizes;

/* to combine with position: "fixed" */
export type AlertPlacement = 'bottom' | 'top';

interface StyledAlertProps {
  $size?: AlertSize;
  $status?: AlertStatus;
  $position: CSSProperties['position'];
  $placement?: AlertPlacement;
}

const getStatusStyle = ({ $status = 'info' }: StyledAlertProps) => {
  switch ($status) {
    case 'warning':
      return css`
        background: ${colors.yellow};
        color: ${colors.black};

        button {
          background: ${colors.black};
          color: ${colors.white};
        }
      `;
    case 'error':
      return css`
        background: ${colors.hellNo};
        color: ${colors.white};

        button {
          background: ${colors.white};
          color: ${colors.black};
        }
      `;
    case 'success':
      return css`
        background: ${colors.hellYeah};
        color: ${colors.black};

        button {
          background: ${colors.black};
          color: ${colors.white};
        }
      `;
    case 'info':
    default:
      return css`
        flex-direction: column;
        align-items: flex-start;
        background: ${colors.white};
        color: ${colors.black};
        border: 1px solid ${colors.gray4};

        p {
          padding-block-end: ${spacing.small};
        }

        margin: ${spacing.small};
        width: calc(100% - 32px);

        @media screen and (min-width: ${breakpoints.mobileMax}) {
          margin: ${spacing.regular};
          max-inline-size: 330px;
        }
      `;
  }
};

const getSizeStyle = ({ $size = 'medium' }: StyledAlertProps) => {
  switch ($size) {
    case 'small':
      return css`
        padding: ${spacing.xsmall};
      `;
    case 'large':
      return css`
        padding: ${spacing.medium};
      `;
    case 'medium':
    default:
      return css`
        padding: ${spacing.small};
      `;
  }
};

const getPositionStyle = ({ $position, $placement }: StyledAlertProps) => css`
  position: ${$position};
  ${$position === 'absolute'
    ? css`
        inset: ${$placement === 'top' ? 0 : 'auto'} 0
          ${$placement === 'bottom' ? 0 : 'auto'} 0;
        width: 100%;
        margin: 0 auto;
      `
    : $position === 'fixed' &&
      css`
        inset: ${$placement === 'top' ? 0 : 'auto'} 0
          ${$placement === 'bottom' ? 0 : 'auto'} 0;
        width: 100%;
        margin: 0 auto;
      `};
`;

export const StyledAlert = styled.div<StyledAlertProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing.small} auto;
  z-index: 3;

  button {
    appearance: none;
    padding: ${spacing.xsmall} ${spacing.small};
    border: none;
    font-size: ${fontSizes.body14};
    min-block-size: 0;
  }

  ${getPositionStyle};
  ${getStatusStyle};
  ${getSizeStyle};
  .closeButton {
    inline-size: 100%;
    display: flex;
    justify-content: flex-end;

    &:hover {
      cursor: pointer;
    }
  }
`;
