import clsx from 'clsx';

export type HTagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

export type HTagStyleType =
  | 'header1Bold'
  | 'header1'
  | 'header1Medium'
  | 'header1Mobile'
  | 'header1MobileMedium'
  | 'header2Medium'
  | 'header2Bold'
  | 'header2'
  | 'header3'
  | 'header4'
  | 'header4Medium';

interface HTagProps {
  className?: string;
  type?: HTagType;
  children?: React.ReactNode;
  whiteText?: boolean;
  center?: boolean;
  styleType?: HTagStyleType;
}

const HTagElement: React.FC<HTagProps> = ({ type, children, className }) => {
  switch (type) {
    case 'h1':
      return <h1 className={className}>{children}</h1>;
    case 'h2':
      return <h2 className={className}>{children}</h2>;
    case 'h3':
      return <h3 className={className}>{children}</h3>;
    case 'h4':
      return <h4 className={className}>{children}</h4>;
    case 'h5':
      return <h5 className={className}>{children}</h5>;
    case 'h6':
      return <h6 className={className}>{children}</h6>;
    case 'p':
      return <p className={className}>{children}</p>;
    case 'span':
      return <span className={className}>{children}</span>;
    default:
      return <h1 className={className}>{children}</h1>;
  }
};

export const HTag: React.FC<HTagProps> = ({
  type = 'h1',
  className,
  whiteText = false,
  center = false,
  styleType = 'header1',
  children,
}) => {
  const baseClass =
    typeof styleType === 'string'
      ? styleType?.replace(/^header(\d).*/, 'h$1')
      : 'h1';

  return (
    <HTagElement
      type={type}
      className={clsx(
        baseClass,
        /mobile/i.test(styleType) && 'mobile',
        /bold/i.test(styleType) && 'bold',
        /medium/i.test(styleType) && 'medium',
        center && 'center',
        whiteText && 'white',
        className,
      )}
    >
      {children}
    </HTagElement>
  );
};
