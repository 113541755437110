import { ImageLoadFragment } from '@hultafors/hultaforsgroup/types';

import { HeroBlock } from '../hero-block/hero-block';

interface HeroProps {
  title?: string;
  hideTitle?: boolean;
  description?: string;
  image?: ImageLoadFragment;
  priority?: boolean;
}

export const Hero: React.FC<HeroProps> = ({
  title,
  hideTitle = false,
  description,
  image,
  priority = false,
}) => {
  if (!title && !image?.url) {
    return null;
  }

  return (
    <HeroBlock
      index={priority ? 0 : 1}
      title={hideTitle ? '' : title}
      description={description}
      image={image}
      priority={priority}
    />
  );
};
