// Todo change this according to hultafors group style-guide
export const fontSizes = {
  body12: '12px',
  body14: '14px',
  body16: '16px',
  body20: '20px',
  header1: '60px',
  header1Mobile: '40px',
  header2: '30px',
  header2Medium: '32px',
  header3: '20px',
  header4: '20px',
  label: '14px',
} as const;
