import { Fragment } from 'react';

import clsx from 'clsx';

import type { TextBlockFragment } from '@hultafors/hultaforsgroup/types';

import { Markdown } from '../markdown/markdown';

import styles from './text-block.module.scss';

type TextBlockProps = Omit<TextBlockFragment, 'id'>;

export const TextBlock: React.FC<TextBlockProps> = ({
  smallHeader,
  header,
  text,
  introText,
}) => {
  const baseClass = smallHeader ? 'h4' : 'h2';

  return (
    <section className={clsx('content-container', styles['wrapper'])}>
      {header && (
        <h2 className={clsx(baseClass, 'medium', styles['title'])}>{header}</h2>
      )}
      {introText && (
        <h3 className={clsx('h3', styles['intro'])}>{introText}</h3>
      )}
      {text && (
        <div className={clsx('p', styles['text'])}>
          <Markdown options={{ forceWrapper: true, wrapper: Fragment }}>
            {text}
          </Markdown>
        </div>
      )}
    </section>
  );
};
