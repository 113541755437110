import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

import { contentContainerStyle } from '../content-container/content-container.styled';
import { HTag } from '../h-tag/h-tag';

export const OfficesBlockStyled = styled.div`
  ${contentContainerStyle};
  padding: 0 ${spacing.regular};
  padding-bottom: ${spacing.large};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0;
    padding-block-end: ${spacing.large};
  }
`;

export const Name = styled(HTag).attrs({ styleType: 'header4', type: 'h2' })``;
