import {
  ArticlesBlockFragment,
  BrandsBlockFragment,
  BulletListBlockFragment,
  EmployeeBlockFragment,
  HeroBlockFragment,
  ImageBlockFragment,
  JobsListBlockFragment,
  NewsListBlockFragment,
  OfficesBlockFragment,
  PlugBlockFragment,
  SimpleTitleAndTextBlockFragment,
  StatementBlockFragment,
  TextBlockFragment,
  TwoColumnImageAndTextBlockFragment,
  TwoColumnTextIntroBlockFragment,
  VideoBlockFragment,
} from '../dato';

export function isArticlesBlock(item: any): item is ArticlesBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ArticlesBlockRecord';
}

export function isBulletListBlock(item: any): item is BulletListBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'BulletListBlockRecord';
}

export function isEmployeeBlock(item: any): item is EmployeeBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'EmployeeRecord';
}

export function isHeroBlock(item: any): item is HeroBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'HeroBlockRecord';
}

export function isImageBlock(item: any): item is ImageBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ImageRecord';
}

export function isOfficesBlock(item: any): item is OfficesBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'OfficesBlockRecord';
}

export function isOurBrandsBlock(item: any): item is BrandsBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'BrandsBlockRecord';
}

export function isPlugBlock(item: any): item is PlugBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'PlugBlockRecord';
}

export function isSimpleTitleAndTextBlock(
  item: any,
): item is SimpleTitleAndTextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'SimpleTitleAndTextBlockRecord';
}

export function isStatementBlock(item: any): item is StatementBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'StatementBlockRecord';
}

export function isTextBlock(item: any): item is TextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'TextBlockRecord';
}

export function isTwoColumnTextImageBlock(
  item: any,
): item is TwoColumnImageAndTextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'TwoColumnImageAndTextBlockRecord';
}

export function isTwoColumnTextIntroBlock(
  item: any,
): item is TwoColumnTextIntroBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'TwoColumnTextIntroBlockRecord';
}

export function isVideoBlock(item: any): item is VideoBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'VideoBlockRecord';
}

export function isNewsListBlock(item: any): item is NewsListBlockFragment {
  if (!('__typename' in item)) {
    return false;
  }
  return item.__typename === 'NewsListBlockRecord';
}

export function isJobsListBlock(item: any): item is JobsListBlockFragment {
  if (!('__typename' in item)) {
    return false;
  }
  return item.__typename === 'JobsListBlockRecord';
}
