import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

interface StyledDynamicContentProps {
  $padding?: CSSProperties['padding'];
}

export const StyledDynamicContent = styled.div<StyledDynamicContentProps>`
  ${({ $padding }) =>
    $padding
      ? css`
          padding: ${$padding};
        `
      : css`
          padding-top: ${spacing.medium};
          padding-bottom: ${spacing.medium};

          @media screen and (min-width: ${breakpoints.mobileMax}) {
            padding-block: ${spacing.large};
          }
        `}

  & > *:first-child {
    margin-block-start: 0;
    padding-block-start: 0;
  }

  & > *:last-child {
    margin-block-end: 0;
    padding-block-end: 0;
  }
`;
