import styles from './navigation-wrapper.module.scss';

interface NavigationWrapperProps {
  children?: React.ReactNode;
  disabled?: boolean;
  isOpen?: boolean;
}

export const NavigationWrapper: React.FC<NavigationWrapperProps> = ({
  children,
}) => {
  return <div className={styles['wrapper']}>{children}</div>;
};
