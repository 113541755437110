import clsx from 'clsx';

type ParagraphStyleType = 'body16' | 'body14' | 'label';

export interface ParagraphProps {
  className?: string;
  children?: React.ReactNode;
  as?: string | React.ComponentType<any>;
  style?: React.CSSProperties;
  whiteText?: boolean;
  styleType?: ParagraphStyleType;
}

export const Paragraph: React.FC<ParagraphProps> = ({
  className,
  as,
  children,
  styleType,
  whiteText,
  style,
  ...rest
}) => {
  const props = {
    className: clsx(
      'p',
      styleType === 'body14' && 'small',
      styleType === 'label' && 'label',
      whiteText && 'white',
      className,
    ),
    style,
    ...rest,
  };
  if (as === 'div') {
    return <div {...props}>{children}</div>;
  }
  if (as === 'span') {
    return <span {...props}>{children}</span>;
  }
  return <p {...props}>{children}</p>;
};
