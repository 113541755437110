import {
  JobOpportunitiesPageListingFragment,
  NewsPageListingFragment,
} from '../dato';

export function isNewsStory(item: any): item is NewsPageListingFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'NewsPageRecord';
}

export function isJobListing(
  item: any,
): item is JobOpportunitiesPageListingFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'JobOpportunitiesPageRecord';
}
