import clsx from 'clsx';

import { HTagStyleType } from '../h-tag/h-tag';

import styles from './small-hero.module.scss';

interface SmallHeroProps {
  topLabel?: string;
  title?: string;
  styleType?: HTagStyleType;
  noMarginBottom?: boolean;
  className?: string;
}

export const SmallHero: React.FC<SmallHeroProps> = ({
  topLabel,
  title,
  className,
}) => {
  if (!title) {
    return null;
  }

  return (
    <hgroup
      className={clsx('content-container', styles['wrapper'], className)}
      data-test="SmallHero"
    >
      {topLabel && (
        <p className={clsx('p', 'label', styles['label'])}>{topLabel}</p>
      )}
      <h1 className="h2 medium">{title}</h1>
    </hgroup>
  );
};
