import React from 'react';

import { ImageProps, ImgProps } from 'next/dist/shared/lib/get-img-props';
import { getImageProps } from 'next/image';

import { datoImageLoader } from '@hultafors/shared/helpers/dato-image-loader/dato-image-loader';

import { ImageLoadFragment } from '@hultafors/hultaforsgroup/types';

interface ResponsiveImageProps {
  alt?: string | null;
  image?: ImageLoadFragment | null;
  priority?: boolean;
  sizes?: string;
  className?: string;
  aspectRatioPortrait?: number;
  aspectRatioLandscape?: number;
  maxSize?: number;
  style?: React.CSSProperties;
}

export const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  image,
  alt: altInput,
  priority,
  sizes = '100vw',
  className,
  aspectRatioPortrait = 3 / 4,
  aspectRatioLandscape = 21 / 9,
  maxSize = 2880,
  style,
}) => {
  let portrait: ImgProps | null = null;
  let landscape: ImgProps | null = null;

  const alt = altInput || '';

  if (image?.url) {
    const imageUrl = new URL(image.url);
    imageUrl.searchParams.set('q', '85');
    imageUrl.searchParams.set('crop', 'focalpoint');
    imageUrl.searchParams.set('fit', 'crop');
    imageUrl.searchParams.set('fp-x', String(image.focalPoint?.x || 0.5));
    imageUrl.searchParams.set('fp-y', String(image.focalPoint?.y || 0.5));
    imageUrl.searchParams.set('h', String(maxSize));
    imageUrl.searchParams.set('w', String(maxSize));
    const common: Omit<ImageProps, 'src'> = {
      alt,
      loader: datoImageLoader,
      priority,
      quality: 85,
      sizes,
    };

    const widthPortrait = maxSize * aspectRatioPortrait;
    const heightLandscape = maxSize / aspectRatioLandscape;
    const imageUrlPortrait = new URL(imageUrl.href);
    imageUrlPortrait.searchParams.set('w', String(widthPortrait));
    const imageUrlLandscape = new URL(imageUrl.href);
    imageUrlLandscape.searchParams.set('h', String(heightLandscape));

    portrait = getImageProps({
      ...common,
      height: maxSize,
      src: imageUrlPortrait.href,
      width: widthPortrait,
    }).props;

    landscape = getImageProps({
      ...common,
      height: heightLandscape,
      src: imageUrlLandscape.href,
      width: maxSize,
    }).props;
  }

  const loading = priority ? 'eager' : 'lazy';

  return (
    <figure className={className} style={style}>
      {portrait && landscape && (
        <picture>
          <source srcSet={portrait.srcSet} media="(orientation: portrait)" />
          <source srcSet={landscape.srcSet} media="(orientation: landscape)" />
          <img src={portrait.src} alt={alt} sizes={sizes} loading={loading} />
        </picture>
      )}
    </figure>
  );
};
