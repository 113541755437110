import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

import { IconButtonWrapper } from '../icon-button-wrapper/icon-button-wrapper';

export const NavigationMobileStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  background-color: ${colors.HGBlack};
  align-items: center;

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    display: none;
  }

  span {
    align-self: center;
  }

  .MenuActions {
    align-self: center;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;

    > div {
      margin-inline-start: ${spacing.xsmall};
      display: flex;
    }
  }
`;

export const LogoLink = styled(Link)`
  color: ${colors.white};
`;

export const MenuToggler = styled(IconButtonWrapper).attrs({ name: 'menu' })`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  width: ${spacing.regular};
`;

export const TogglerButton = styled.button`
  margin-inline: 8px;

  &:hover {
    cursor: pointer;
  }
`;
