import styled from 'styled-components';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

import { HTag } from '../h-tag/h-tag';

export const StyledContactInformationBlock = styled.div`
  margin-bottom: ${spacing.xregular};

  .Title {
    margin-block-end: ${spacing.small};
  }

  &:last-of-type {
    margin-block-end: ${spacing.large};
  }
`;

export const Title = styled(HTag).attrs({
  className: 'Title',
  styleType: 'header3',
  type: 'h2',
})``;
