import { Button } from '../button/button';

import {
  ButtonWrapper,
  MoreNewsWrapperWrapper,
  Title,
} from './more-news-wrapper.styled';

interface MoreNewsWrapperProps {
  bigTitle?: boolean;
  title?: string;
  CTAText?: string;
  CTAUrl?: string;
  children?: React.ReactNode;
}

export const MoreNewsWrapper: React.FC<MoreNewsWrapperProps> = ({
  bigTitle = false,
  title,
  children,
  CTAText,
  CTAUrl,
}) => {
  return (
    <MoreNewsWrapperWrapper data-test="MoreNewsWrapper">
      <Title className={bigTitle ? 'h1' : 'h2'}>{title}</Title>
      {children}
      <ButtonWrapper>
        <Button to={CTAUrl}>{CTAText}</Button>
      </ButtonWrapper>
    </MoreNewsWrapperWrapper>
  );
};
