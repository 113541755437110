import { useRef, useState } from 'react';

import { useGlobal } from '@hultafors/hultaforsgroup/hooks';
import { VideoFragment } from '@hultafors/hultaforsgroup/types';

import PlaySvg from '../../svg/play.svg';
import { ContentContainer } from '../content-container/content-container';

import { StyledVideo } from './video.styled';

interface VideoProps {
  video: VideoFragment['video'];
  autoPlay?: boolean;
  thumbnail?: string;
}

export const Video: React.FC<VideoProps> = ({ video, thumbnail, ...props }) => {
  const { globalContent: global } = useGlobal();
  const [paused, setPaused] = useState(true);
  const videoPlayer = useRef<HTMLVideoElement>(null);

  const handleOnPlay = () => {
    videoPlayer.current?.setAttribute('controls', 'true');
    setPaused(false);
  };

  function playVideo() {
    videoPlayer.current?.play();
    handleOnPlay();
  }

  return video?.video ? (
    <StyledVideo>
      <ContentContainer wide>
        <div className="video-wrapper">
          <video
            ref={videoPlayer}
            poster={thumbnail ? thumbnail : video.video.thumbnailUrl}
            autoPlay={props.autoPlay}
            onPlay={handleOnPlay}
          >
            <source src={`${video.video.streamingUrl}`} />
            <source src={`${video.video.mp4Low}`} />
            <p>
              Your browser doesn&apos;t support HTML5 video. Here is a{' '}
              <a href={video.video.mp4Low || ''}>link to the video</a> instead.
            </p>
          </video>
          <div className="controls">
            <button
              title="play"
              onClick={playVideo}
              className={paused ? 'visible' : ''}
              aria-label={global.play || ''}
            >
              <PlaySvg
                width={12}
                height={14}
                aria-hidden={true}
                focusable={false}
              />
            </button>
          </div>
        </div>
      </ContentContainer>
    </StyledVideo>
  ) : null;
};
