export const lineHeights = {
  body12: '18px',
  body14: '22px',
  body16: '24px',
  header1: '70px',
  header1Mobile: '51px',
  header2: '38px',
  header2Medium: '37px',
  header3: '26px',
  header4: '26px',
  label: '14px',
} as const;
