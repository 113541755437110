import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

export const NewsLabelWrapper = styled.div`
  padding: 0 ${spacing.small};
  margin-bottom: ${spacing.medium};

  * + & {
    margin-block-start: ${spacing.medium};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 0;
  }
`;
