import styled from 'styled-components';

import { colors } from '@hultafors/hultaforsgroup/helpers';

export const StyledToggleSwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  min-width: 48px;
  height: 32px;

  input:checked + .slider {
    background-color: ${colors.HGBlue};
  }

  input:checked + .slider::before {
    transform: translateX(17px);
    box-shadow: none;
  }
`;

export const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const StyledSliderSpan = styled.span`
  position: absolute;
  cursor: pointer;
  inset: 0;
  border-radius: 16px;
  background-color: ${colors.gray5};
  transition: 0.2s;

  ::before {
    border-radius: 50%;
    position: absolute;
    content: '';
    block-size: 26px;
    inline-size: 26px;
    inset-inline-start: 3px;
    inset-block-end: 3px;
    background-color: ${colors.white};
    transition: 0.2s;
    box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
  }
`;
